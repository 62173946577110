<template>
    <div v-if="loaded">
        <div class="animate__animated animate__fadeIn">
            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.information.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question name column">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.name.title')}}</p>
                            </div>
                            
                            <input :class="['input input-text', error('name')]" v-model="data.name" type="text">
                            <FormError :data="validation.name" />                
                        </div>

                        <OfferDescriptionComponent :description="data.description" @update="update($event)"  />

                        <!-- <div class="question tags column">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.tags.title')}}</p>
                                <p class="subtitle">{{$t('workProviderOffer.shared.card.tags.subtitle')}}</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading with-subtitle">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.classification.title')}}</p>
                    <p class="subtitle">{{$t('workProviderOffer.shared.card.split.classification.subtitle')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <ClassificationComponent    :classification="data.classification"
                                                    :salaries="data.salaries"
                                                    :validation="validation"
                                                    client="work-provider"
                                                    @update="update($event)" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.schedule.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question schedule-type column">   
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.schedule_type.title')}}</p>
                            </div>

                            <multiselect    v-model="data.schedule_type" 
                                            :options="schedule_type_choices"
                                            :close-on-select="true"
                                            :multiple="false"
                                            :searchable="false"
                                            :class="error('schedule_type')"
                                            :placeholder="$t('workProviderOffer.shared.card.schedule_type.placeholder')"

                                            :select-label="$t('language.multiselect.label.select')"
                                            :selected-label="$t('language.multiselect.label.selected')"
                                            :deselect-label="$t('language.multiselect.label.deselect')"
                                            :custom-label="translate_schedule_type"> 
                            </multiselect>  

                            <FormError :data="validation.schedule_type" />                
                        </div>
                        
                        <div v-if="data.schedule_type">
                            <WorkDaysComponent  :workDays="data.workDays"
                                                :workTimes="data.workTimes"
                                                :validation="validation"
                                                client="workProvider"
                                                @update="update($event)" />

                            <WorkTimesComponent  v-if="data.workDays"
                                                :workDays="data.workDays"
                                                :workTimes="data.workTimes"
                                                :hoursPerWeek="data.hoursPerWeek"
                                                :schedule_type="data.schedule_type"
                                                :hours_per_week_persons_choice="data.hours_per_week_persons_choice"
                                                :validation="validation"
                                                client="workProvider"
                                                @update="update($event)" />

                            <OfferSchedulePrecisionComponent :schedule_precision="data.schedule_precision" @update="update($event)"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.compensation.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <SalariesComponent  :salaries="data.salaries"
                                            :validation="validation" />
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.benefits.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <BenefitsComponent  :benefits="data.benefits" />
                    </div>
                </div>
            </div>

            <div class="card card-split" id="card-split-location">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.location.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <!-- <div class="question work-location column">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.workLocation.title')}}</p>
                                <p class="subtitle"><font-awesome-icon :icon="['fa', 'circle-exclamation']" class="warning" />{{$t('workProviderOffer.shared.card.workLocation.subtitle')}}</p>
                            </div>

                            <div class="checkbox-container">
                                <div class="checkbox-with-label" @click="regularOption = !regularOption">
                                    <input type="checkbox" v-model="regularOption">
                                    <p class="label">{{$t('regular')}}</p>
                                </div>
                                <div class="checkbox-with-label" @click="remoteOption = !remoteOption">
                                    <input type="checkbox" v-model="remoteOption">
                                    <p class="label">{{$t('remote')}}</p>
                                </div>
                                <div class="checkbox-with-label" @click="hybridOption = !hybridOption">
                                    <input type="checkbox" v-model="hybridOption">
                                    <p class="label">{{$t('hybride')}}</p>
                                </div>
                                <div class="checkbox-with-label" @click="bothOption = !bothOption">
                                    <input type="checkbox" v-model="bothOption">
                                    <p class="label">{{$t('both')}}</p>
                                </div>
                            </div>

                            <FormError :data="validation.workLocation" />
                        </div>

                        <HybridComponent  v-if="hybridOption"
                                            :hybrid="data.hybrid"
                                            :validation="validation"
                                            @update="update($event)" /> -->
                        
                        <div v-if="data.workLocation === 'regular' || data.workLocation === 'both'" class="question locations column">
                            <div class="label-container">
                                <p v-if="hybridOption">{{$t('workProviderOffer.shared.card.locations.title.hybrid')}}</p>
                                <p v-else>{{$t('workProviderOffer.shared.card.locations.title.default')}}</p>
                                <p class="subtitle">{{$t('workProviderOffer.shared.card.locations.subtitle')}}</p>
                            </div>

                            <div v-if="locations.length !== 0" :class="['location-container', error('locations')]">
                                <div :class="['location', { active: data.locations.includes(location._id) }]"  v-for="location in locations" :key="location._id" @click="selectLocation(location._id)">
                                    <div class="text">
                                        <span>
                                            <font-awesome-icon :class="{ active: data.locations.includes(location._id) }" :icon="data.locations.includes(location._id) ? ['fa', 'circle-check'] : ['far', 'circle']" />
                                            <p class="name">{{location.name}}</p>
                                        </span>

                                        <p class="address">{{location.formattedAddress}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="create-more">
                                <span class="span" v-html="$t(`workProviderOffer.shared.card.locations.create.${locations.length !== 0 ? 'more' : 'empty'}`)"></span>
                                <span class="link" @click="save_progress(false, true, false, false)" v-html="$t('workProviderOffer.shared.card.locations.create.link')"></span>
                            </div>

                            <FormError :data="validation.locations" />
                        </div>

                        <!-- <div class="question primary-contact column">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.primary-contact.title')}}</p>
                                <p class="subtitle">{{ $t('workProviderOffer.shared.card.primary-contact.subtitle') }}</p>
                            </div>

                            <multiselect    v-model="data.primary_contact" 
                                            :options="primary_contact_choices"
                                            :close-on-select="true"
                                            :multiple="false"
                                            :searchable="false"
                                            :class="error('schedule_type')"
                                            :placeholder="$t('workProviderOffer.shared.card.primary-contact.title')"

                                            :select-label="$t('language.multiselect.label.select')"
                                            :selected-label="$t('language.multiselect.label.selected')"
                                            :deselect-label="$t('language.multiselect.label.deselect')"> 
                            </multiselect>  
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="card card-split">
                <div class="card-heading">
                    <p class="title">{{$t('workProviderOffer.shared.card.split.dates.title')}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="question start-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.startDate.title')}}</p>
                                <FormError :data="validation.startDate" />
                            </div>

                            <VueDatePicker  v-model="data.startDate" 
                                            :class="error('startDate')"
                                            :min-date="today"
                                            :locale="{lang: currentLanguage}"
                                            color="#303030" />
                        </div>

                        <div class="question has-end-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.endDate.needed')}}</p>
                            </div>

                            <div class="button-group collapsed">
                                <button :class="['cta cta-option', { active: hasEndDate }]" @click="hasEndDate = true">{{$t('language.yes')}}</button>
                                <button :class="['cta cta-option', { active: !hasEndDate }]" @click="hasEndDate = false">{{$t('language.no')}}</button>
                            </div>
                        </div>

                        <div v-if="hasEndDate" class="question end-date">
                            <div class="label-container">
                                <p>{{$t('workProviderOffer.shared.card.endDate.title')}}</p>
                                <FormError :data="validation.endDate" />
                            </div>

                            <VueDatePicker  v-model="data.endDate" 
                                            :class="error('endDate')"
                                            :min-date="today"
                                            :locale="{ lang: currentLanguage }"
                                            color="#303030" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="modal" :class="{ active: delete_modal.modal }">
            <div class="modal-card">
                <p class="title">{{$t('workProviderOffer.shared.card.delete.confirmation')}}</p>
                <div class="cta-container">
                    <div class="cta cta-error dark cta-slim" @click="delete_progress()" :class="{ loading: delete_modal.loading }">{{$t('language.yes')}}</div>
                    <div class="cta cta-outline dark cta-slim" @click="close_delete_modal()">{{$t('language.no')}}</div>
                </div>
            </div>
        </div>

        <PublishWorkProviderOfferModal :publish_modal="publish_modal" :work_location="data.workLocation" :locations="data.locations" @close="close_publish_modal()" @create="create_offer()" />
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import FormError from '../../../../../components/Utils/FormError.vue'
import WorkDaysComponent from '../../../../Form/WorkDaysComponent.vue';
import WorkTimesComponent from '../../../../Form/WorkTimesComponent.vue';
import ClassificationComponent from '../../../../Form/ClassificationComponent.vue';
import SalariesComponent from '../../../../Form/SalariesComponent.vue';
import BenefitsComponent from '../../../../Form/BenefitsComponent.vue';
import OfferDescriptionComponent from '../../../../Form/OfferDescriptionComponent.vue';
import OfferSchedulePrecisionComponent from '../../../../Form/OfferSchedulePrecisionComponent.vue';
import PublishWorkProviderOfferModal from '../../../../Modals/MatchDetail/WorkProvider/PublishWorkProviderOfferModal.vue';

export default {
    name: 'CreateWorkProviderPaidOfferCard',

    components: { FormError, WorkDaysComponent, WorkTimesComponent, ClassificationComponent, SalariesComponent, BenefitsComponent, PublishWorkProviderOfferModal, OfferDescriptionComponent, OfferSchedulePrecisionComponent },

    props: {
        id: {
            type: String,
            default: null
        },

        trigger_delete_offer: {
            type: Boolean,
            default: false,
        },

        trigger_save_progress: {
            type: Boolean,
            default: false,
        },
        
        trigger_publish_offer: {
            type: Boolean,
            default: false,
        },

        trigger_navigate_back: {
            type: Boolean,
            default: false,
        }
    },
    
    data() {
        return {
            loaded: false,

            data: {
                name: '',
                description: '',
                schedule_precision: '',

                primary_contact: null,

                classification: {
                    interests: [],
                    experienceLevels: [],
                    all: null,
                    query: ''
                },

                workDays: [],
                workTimes: [],
                hoursPerWeek: '',
                calculated_hours_per_week: 0,
                schedule_type: '',
                hours_per_week_persons_choice: false,
                workLocation: 'regular',
                salaries: [],
                reserved_for_students: false,

                managementPosition: false,
                availableOnCall: false,
                
                locations: [],

                startDate: null,
                endDate: null,

                hybrid: {
                    hybrid: false,
                    days: {
                        number: 1,
                        period: 'weekly'
                    }
                },

                benefits: {
                    choices: [],
                    other: ''
                },
            },

            schedule_type_choices: ['exact', 'flexible'],
            primary_contact_choices: ['Sebastien Garneau', 'Christina Filloles-Deblois'],

            offer_description_height: '75px',
            
            regularOption: false,
            remoteOption: false,
            hybridOption: false,
            bothOption: false,

            today: new Date(),

            locations: [],

            validation: {
                name: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                classification: {
                    interests: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    },

                    experienceLevels: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    }
                    
                },

                workDays: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                locations: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                hoursPerWeek: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                schedule_type: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                startDate: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                endDate: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                salaries: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                workTimes: {
                    monday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    tuesday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    wednesday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    thursday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    friday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    saturday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    },

                    sunday: {
                        start: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.start'
                        },

                        end: {
                            error: false,
                            key: 'errors.validation.timeFormatInvalid.end'
                        }
                    }
                },

                workLocation: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                hybrid: {
                    days: {
                        number: {
                            error: false,
                            key: 'errors.validation.fieldMandatory'
                        }
                    }
                },
            },

            sectorOptions: [],
            classOptions: [],
            jobOptions: [],

            hasEndDate: false,

            formValidated: false,
            loading: false,
            
            delete_modal: {
                loading: false,
                modal: false
            },

            publish_modal: {
                loading: false,
                modal: false,

                not_enough_credits: false,
                missing_credits: 0,
                credit_unit_cost: 0
            }
        }
    },

    watch: {
        hybridOption: function() {
            const self = this;
            self.data.hybrid.hybrid = self.hybridOption

            if(self.hybridOption) {
                self.regularOption = false; 
                self.remoteOption = false; 
                self.bothOption = false;

                if(self.validation.workLocation.error === true) {
                    self.validation.workLocation.error = false;
                }
            } else if (!self.hybridOption) {
                self.data.hybrid.hybrid = false;
                self.data.hybrid.days.number = 1;
                self.data.hybrid.days.period = 'weekly';
                self.data.locations = [];
            }
            
            self.setWorkLocation();
        },

        remoteOption: function() {
            const self = this;
            if(self.remoteOption) { 
                self.hybridOption = false; 
                self.regularOption = false; 
                self.bothOption = false; 

                if(self.validation.workLocation.error === true) {
                    self.validation.workLocation.error = false;
                }
            } else if (!self.regularOption) {
                self.data.locations = [];
            }

            self.setWorkLocation();
        },


        regularOption: function() {
            const self = this;
            if(self.regularOption) { 
                self.hybridOption = false; 
                self.remoteOption = false; 
                self.bothOption = false;

                if(self.validation.workLocation.error === true) {
                    self.validation.workLocation.error = false;
                }
            } else if (!self.regularOption) {
                self.data.locations = [];
            }

            self.setWorkLocation();
        },

        bothOption: function() {
            const self = this;
            if(self.bothOption) { 
                self.hybridOption = false; 
                self.remoteOption = false; 
                self.regularOption = false;

                if(self.validation.workLocation.error === true) {
                    self.validation.workLocation.error = false;
                }
            } else if (!self.bothOption) {
                self.data.locations = [];
            }

            self.setWorkLocation();
        },

        'data.classification': {
            deep: true,
            handler() {
                const self = this;
                self.$emit('update', self.data.classification)
            }
        },

        'data.workTimes':  {
            deep: true,
            handler() {
                const self = this;
                if(self.data.workTimes.length > 0) {
                    if(!isNaN(self.$utils._calculate_hours_per_week_from_work_times(self.data.workTimes))) {
                        self.data.calculated_hours_per_week = self.$utils._calculate_hours_per_week_from_work_times(self.data.workTimes);
                    }else {
                        self.data.calculated_hours_per_week = 0;
                    }
                }else {
                    self.data.calculated_hours_per_week = 0;
                }
            }
        },

        data: {
            deep: true,
            handler() {
                const self = this;
                self.throttle_save_progress();
            }
        },

        'delete_modal.modal': function() {
            const self = this;
            if(self.delete_modal.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },

        'publish_modal.modal': function() {
            const self = this;
            if(self.publish_modal.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },

        trigger_delete_offer: function() {
            const self = this;
            if(self.trigger_delete_offer) {
                self.delete_modal.modal = true;
            }
        },

        trigger_save_progress: function() {
            const self = this;
            if(self.trigger_save_progress) {
                self.save_progress(true, false, false, false);
            }
        },

        trigger_publish_offer: function() {
            const self = this;
            if(self.trigger_publish_offer) {
                self.save_progress(false, false, false, true);
            }
        },

        trigger_navigate_back: function() {
            const self = this;
            if(self.trigger_navigate_back) {
                self.save_progress(true, false, true, false);
            }
        }
    },

    computed: {
        ...mapGetters([
            'currentProfile',
            'currentProfileType',
            'currentLanguage'
        ])
    },

    methods: {
        create_offer: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.publish_modal.loading = true;
                self.data.unpublished_offer_id = self.id;

                if(self.data.schedule_type === 'exact') {
                    self.data.hoursPerWeek = self.data.calculated_hours_per_week;
                }

                if(self.data.hours_per_week_persons_choice === true) {
                    self.data.hoursPerWeek = '0';
                }

                self.$axios
                    .post('/work-provider/offer/paid', { ...self.data })
                    .then(response => {
                        if(response.status == 200){
                            self.publish_modal.loading = false;
                            self.$utils._navigate_to_name('work-provider-offers');
                        }
                    })
                    .catch(error => {
                        self.publish_modal.loading = false;
                        if(error && error.response && error.response.data && error.response.data.error && error.response.data.error.key === 'notEnoughCredits') {
                            self.publish_modal.not_enough_credits = true;
                            self.publish_modal.missing_credits = error.response.data.missing_credits;
                            self.publish_modal.credit_unit_cost = error.response.data.credit_unit_cost;
                        }else {
                            if(error && error.response) {
                                if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                            } else { console.log(error); }
                        }
                    });
            } else {
                self.close_publish_modal();
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        setWorkLocation: function() {
            const self = this;
            
            self.data.workLocation = '';

            if(self.hybridOption) { self.data.workLocation = 'regular' }
            else if(self.regularOption) { self.data.workLocation = 'regular' }
            else if(self.remoteOption) { self.data.workLocation = 'remote' }
            else if(self.bothOption) { self.data.workLocation = 'both' }
        },

        validate_mandatory_fields: function() {
            const self = this;
            self.resetValidation();

            ['name', 'workLocation', 'startDate', 'schedule_type'].forEach(key => {
                if(!self.data[key] || self.data[key] === '')
                    self.validation[key].error = true;
            });

            if(self.data.schedule_type === 'flexible') {
                if(!self.data.hours_per_week_persons_choice) {
                    if(!self.data.hoursPerWeek || self.data.hoursPerWeek === '' || parseFloat(self.data.hoursPerWeek) === 0) {
                        self.validation.hoursPerWeek.error = true;
                        self.formValidated = false;
                    }else if(parseFloat(self.data.hoursPerWeek) > self.data.calculated_hours_per_week) {
                        self.validation.hoursPerWeek.error = true;
                        self.validation.hoursPerWeek.key = 'errors.validation.hours_per_week_too_high_employer';
                        self.formValidated = false;
                    }
                }
            }
            
            ['workDays'].forEach(key => {
                if(!self.data[key] || self.data[key].length === 0) {
                    self.validation[key].error = true;
                }
            });
            
            if(self.data.classification.interests.length === 0) {
                self.validation.classification.interests.error = true;
                self.formValidated = false;
            }

            ['experienceLevels'].forEach(key => {
                if(self.data.classification[key].length === 0) {
                    self.validation.classification[key].error = true;
                    self.formValidated = false;
                }
            });

            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                if(self.data.workDays.includes(day)) {
                    const slots = ['start', 'end']
                    const period = self.data.workTimes.find(time => time.day === day)
                    slots.forEach(slot => {
                        if(period.timeSlots[0][slot].value === '' || !self.$utils._validate_time_slot(period.timeSlots[0][slot].value)) {
                            self.validation.workTimes[day][slot].error = true
                            self.validation.workTimes[day][slot].key = `validation.timeFormatInvalid.${slot}`
                        }
                    })
                }
            })

            self.data.salaries.forEach(salary => {
                if(salary.amount === '0' || salary.amount === '') {
                    self.validation.salaries.error = true;
                }

                if(salary.type && salary.amount && salary.type === 'hourly' && !self.$utils._validate_hourly_salary(salary.amount)) {
                    self.validation.salaries.error = true;
                    self.validation.salaries.key = 'errors.validation.salaryTooLow';
                }
            })

            if(self.hasEndDate && !self.data.endDate) {
                self.validation.endDate.error = true;
            }

            if(self.data.workLocation === 'regular' || self.data.workLocation === 'both') {
                if(!self.data.locations || self.data.locations.length === 0) {
                    self.validation.locations.error = true;
                }
            }

            if(self.data.hybrid.hybrid) {
                if(!self.data.hybrid.days.number) {
                    self.validation.hybrid.days.number.error = true;
                    self.formValidated = false
                }
            }

            const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false

                if(val === 'workTimes') {
                    days.forEach(day => {
                        if(self.validation[val][day]['start'].error || self.validation[val][day]['end'].error)
                            self.formValidated = false
                    })
                }
            });
        },

        resetValidation: function() {
            const self = this;

            self.formValidated = true;

            Object.keys(self.validation).forEach(val => {
                if(val === 'workTimes') {
                    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                    days.forEach(day => {
                        self.validation.workTimes[day].start.error = false;
                        self.validation.workTimes[day].end.error = false;
                    })
                } else if (val === 'classification') {
                    ['interests', 'experienceLevels'].forEach(key => {
                        self.validation.classification[key].error = false;
                    })
                } else {
                    self.validation[val].error = false
                }
            });

            self.validation.hybrid.days.number.error = false;
            self.validation.salaries.key = 'errors.validation.fieldMandatory';
            self.validation.hoursPerWeek.key = 'errors.validation.fieldMandatory';
        },

        removeWorkDay: function(day) {
            const self = this;

            self.data.workTimes.splice(self.data.workTimes.map(e => e.day).indexOf(day), 1);
            self.data.workDays.splice(self.data.workDays.indexOf(day), 1);
        },

        selectHybridDay: function(day, type) {
            const self = this;

            if(self.data.hybrid.days[type].includes(day)) {
                if(self.data.hybrid.days[type].indexOf(day) !== -1)
                    self.data.hybrid.days[type].splice(self.data.hybrid.days[type].indexOf(day), 1);
            }else {
                self.data.hybrid.days[type].push(day);
            }
        },

        selectLocation: function(id) {
            const self = this;

            if(self.data.locations.includes(id)) {
                if(self.data.locations.indexOf(id) !== -1)
                    self.data.locations.splice(self.data.locations.indexOf(id), 1);
            }else {
                self.data.locations.push(id)
            }
        },

        getLocations: function() {
            const self = this;

            self.$axios
                .get(`locations`)
                .then(response => {
                    if(response.status == 200){
                        self.locations = response.data.locations;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        translate_schedule_type: function(key) {
            const self = this;
            return self.$t(`workProviderOffer.shared.card.schedule_type.choices.${key}`)
        },

        throttle_save_progress: _.throttle(function() {
            this.save_progress(false, false, false, false);
        }, 1000),  

        save_progress: function(show_message, redirect, navigate_back, trigger_publish_modal) {
            const self = this;

            if(!self.hasEndDate) {
                self.data.endDate = null;
            }

            if(self.id) {
                self.$axios
                    .put(`work-provider/offer/unpublished`, { id: self.id, json: JSON.stringify(self.data) })
                    .then(response => {
                        if(response.status == 200){
                            if(show_message) {
                                self.$toasted.success(
                                    self.$t('notifications.progressSaved'),
                                    { icon: 'circle-check' }
                                );
                            }

                            if(navigate_back) { self.$utils._navigate_back(); }
                            if(trigger_publish_modal) { 
                                self.validate_mandatory_fields();
                                if(self.formValidated) {
                                    self.publish_modal.modal = true; 
                                }else {
                                    self.$toasted.error(
                                        self.$t('notifications.allFieldsAreMandatory'),
                                        { icon: 'circle-exclamation' }
                                    );
                                }
                            }
                            
                            if(redirect) { 
                                if(self.$route && self.$route.path) {
                                    const current_path = self.$router.currentRoute.path;
                                    self.$utils._navigate_to_name_with_query_and_params('create-location', { redirect: current_path }, { type: 'work-provider' })
                                }else { 
                                    self.$utils._navigate_to_name_with_params('create-location', { type: 'work-provider' }) 
                                }
                            }

                            self.$emit('update_publish_offer_triggered');
                            self.$emit('update_save_progress_triggered');
                            self.$emit('update_navigate_back_triggered');
                        }
                    })
                    .catch(error => {
                        self.$emit('update_publish_offer_triggered');
                        self.$emit('update_save_progress_triggered');
                        self.$emit('update_navigate_back_triggered');

                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            }
        },

        close_delete_modal: function() {
            const self = this;
            self.delete_modal.modal = false;
            self.$emit('update_delete_offer_triggered');
        },  

        delete_progress: function() {
            const self = this;

            if(self.id) {
                self.delete_modal.loading = true;
                
                self.$axios
                    .delete(`/work-provider/offer/${self.id}/paid/unpublished`)
                    .then(response => {
                        if(response.status == 200){
                            self.$toasted.success(
                                self.$t('notifications.workProviderOfferArchivedSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.$utils._navigate_to_name('work-provider-offers');
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }

                        self.delete_modal.loading = false;
                    });
            }
        },

        get_unpublished_work_provider_offer: function() {
            const self = this;

            if(self.id) {
                self.$axios
                    .get(`work-provider/offer/${self.id}/paid/unpublished`)
                    .then(response => {
                        if(response.status == 200) {
                            if(response.data.json !== '') {
                                const data = JSON.parse(response.data.json);
                                if(Object.keys(data).length !== 0) {
                                    self.data = data;
                                    
                                    // if(self.data.workLocation === 'remote') {
                                    //     self.remoteOption = true;
                                    // }

                                    // if(self.data.workLocation === 'both') {
                                    //     self.bothOption = true;
                                    // }

                                    // if(self.data.workLocation === 'regular') {
                                    //     if(self.data.hybrid && self.data.hybrid.hybrid) { self.hybridOption = true; }
                                    //     else { self.regularOption = true; }
                                    // }

                                    if(self.data.endDate) {
                                        self.hasEndDate = true
                                    }

                                    self.data.workLocation = 'regular';
                                    self.regularOption = true;
                                }
                            }

                            self.$nextTick(() => {
                                self.loaded = true;
                                self.capture_query();
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }

                        if(error && error.response && error.response.data && error.response.data.key === 'unpublished_work_provider_offer_does_not_exist') {
                            self.$utils._navigate_to_name('work-provider-offers');
                        }

                        self.$nextTick(() => {
                            self.loaded = true;
                        })
                    });
            }else {
                self.loaded = true;
            }
        },

        update: function(data) {
            const self = this;
            
            Object.keys(data).forEach(key => {
                if(key === 'validation') { self.validation = data[key] }
                else { self.data[key] = data[key] }
            });
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },

        close_publish_modal: function() {
            const self = this;
            
            self.publish_modal.modal = false;
            self.publish_modal.loading = false;
            self.publish_modal.not_enough_credits = false;

            self.publish_modal.missing_credits = 0;
            self.publish_modal.credit_unit_cost = 0;

            self.$emit('update_publish_offer_triggered');
        },

        capture_query: function() {
            const self = this;
            if(self.$route.query && self.$route.query.scroll_back) {
                self.$nextTick(() => {
                    //document.getElementById('card-split-location').scrollIntoView({behavior: 'smooth'})
                })
            }
        }
    },

    mounted() {
        const self = this;

        self.getLocations();
        self.get_unpublished_work_provider_offer();   
    }
}
</script>

