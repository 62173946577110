<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope">
        <div id="sticky-heading-container" class="heading-container sticky">
            <div class="back-title-container">
                <BackButton :save_progress="true" @navigate_back_triggered="navigate_back.trigger = true" />
                <h1 class="title">{{$t('workProviderOffer.paid.title.create')}}</h1>
            </div>

            <div class="cta-container">
                <span class="progress"><font-awesome-icon :icon="['fa', 'circle-check']" /><p>{{$t('workProviderOffer.shared.progress')}}</p></span>
                <button class="cta cta-primary cta-slim" @click="publish_offer.trigger = true" :class="{ loading: publish_offer.trigger }">{{$t('workProviderOffer.shared.card.submit.create.label')}}</button>
                <button class="cta cta-outline dark cta-slim" @click="save_progress.trigger = true" :class="{ loading: save_progress.trigger }">{{$t('workProviderOffer.shared.card.submit.save.label')}}</button>
                <button class="cta cta-error cta-slim" @click="delete_offer.trigger = true">{{$t('workProviderOffer.shared.card.submit.delete.label')}}</button>
            </div>
        </div>

        <div id="with-sticky-header" class="row with-sticky-header">
            <div class="left">
                <CreateWorkProviderPaidOfferCard    :id="id"
                                                    :trigger_delete_offer="delete_offer.trigger"
                                                    :trigger_save_progress="save_progress.trigger"
                                                    :trigger_publish_offer="publish_offer.trigger"
                                                    :trigger_navigate_back="navigate_back.trigger"
                                                    @update_delete_offer_triggered="delete_offer.trigger = false"
                                                    @update_save_progress_triggered="save_progress.trigger = false"
                                                    @update_publish_offer_triggered="publish_offer.trigger = false"
                                                    @update_navigate_back_triggered="navigate_back.trigger = false"
                                                    @update="classification = $event"  />
            </div>

            <div class="right">
                <button v-if="valid_recommendations !== null && !valid_recommendations" :class="['cta cta-primary cta-slim cta-unlock-recommendations', { loading: loading, 'nomargin-bottom': expired }]" @click="modal = true">{{$t(expired ? 'recommendations.unlock.expired': 'recommendations.unlock.title')}}</button>
                <span class="expiration-since" v-if="valid_recommendations !== null && !valid_recommendations && expired" v-html="$t('recommendations.unlock.expired_since', { date: expiration_date })" />

                <CompetitionCard :classification="classification" :valid_recommendations="valid_recommendations" :id="id" type="unpublished" />
                <DemandCard :classification="classification" :valid_recommendations="valid_recommendations" />
                <BenefitsCard :classification="classification" :valid_recommendations="valid_recommendations" :id="id" type="unpublished" />
                <SalaryCard :classification="classification" :valid_recommendations="valid_recommendations" :id="id" type="unpublished" />
                
                <span class="expiration-since expires" v-if="!expired && expiration_date" v-html="$t('recommendations.unlock.expires', { date: expiration_date })" />
            </div>
        </div>
        
        <UnlockAdvancedRecommendationsModal :unpublished_offer_id="id" 
                                            :show="modal"
                                            @close="modal = false"
                                            @success="unlock_success()"
                                            type="unpublished" />
    </layout>
</template>

<script>
import { bus } from '../../../../main';

import Layout from '../../../../components/Layout.vue';
import BackButton from '../../../../components/Navigation/BackButton.vue';
import CreateWorkProviderPaidOfferCard from '../../../../components/Cards/Offers/WorkProvider/Paid/CreateWorkProviderPaidOfferCard.vue'

import CompetitionCard from '../../../../components/Cards/Offers/WorkProvider/Recommendations/CompetitionCard.vue';
import DemandCard from '../../../../components/Cards/Offers/WorkProvider/Recommendations/DemandCard.vue';
import BenefitsCard from '../../../../components/Cards/Offers/WorkProvider/Recommendations/BenefitsCard.vue';
import SalaryCard from '../../../../components/Cards/Offers/WorkProvider/SalaryCard.vue'

import UnlockAdvancedRecommendationsModal from '../../../../components/Modals/MatchDetail/WorkProvider/UnlockAdvancedRecommendationsModal.vue';

export default {
    name: 'CreateWorkProviderOffer',

    components: {
        Layout,
        BackButton,
        CreateWorkProviderPaidOfferCard,

        CompetitionCard,
        DemandCard,
        BenefitsCard,
        SalaryCard,
        UnlockAdvancedRecommendationsModal
    },

    props: {
        id: {
            type: String,
            default: null
        },

        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            classification: {
                sector: null,
                class: null,
                jobs: null,
                experienceLevels: []
            },

            routeName: 'create-offer',
            classes: {
                dashboard: true
            },

            loading: false,
            valid_recommendations: true,
            expired: false,
            expiration_date: null,

            modal: false,

            delete_offer: {
                trigger: false
            },

            save_progress: {
                trigger: false
            },

            navigate_back: {
                trigger: false
            },

            publish_offer: {
                trigger: false
            },
        }
    },

    methods: {
        check_valid_recommendations: function() {
            const self = this;

            if(self.id) {
                const instance = self.$axios.create({
                    headers: { 'Authorization': process.env.VUE_APP_RECOMMENDATIONS_LAMBDA_API_KEY }
                })
                
                instance.get(`work-provider/offer/${self.id}/unpublished/link`)
                    .then(response => {
                        if(response.status == 200){
                            const data = response.data;

                            self.valid_recommendations = data.active;
                            self.expired = data.reason && data.reason === 'expired';
                            if(data.expiration) { self.expiration_date = self.$moment(data.expiration).format('DD/MM/YYYY'); }

                            self.$nextTick(() => {
                                bus.$emit('reset_heading_container_margin', true);
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            }
        },

        unlock_success: function() {
            const self = this;

            self.modal = false;
            //self.check_valid_recommendations();
        }
    },

    mounted() {
        //const self = this;
        //self.check_valid_recommendations();
    }
};
</script>