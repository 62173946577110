<template>
    <div class="modal" :class="{ active: publish_modal.modal }">
        <div class="modal-card unlock-recommendations">
            <div class="title-container column">
                <p class="title">{{$t('workProviderOffer.publish.modal.title')}}</p>
                <p class="subtitle">{{$t('workProviderOffer.publish.modal.subtitle')}}</p>
            </div>
            
            <div class="modal-body">
                <!-- <div class="row center">
                    <p class="title">{{$t('recommendations.unlock.modal.cost.title')}}</p>
                    <span class="bubble">{{$t('workProviderOffer.publish.modal.cost', { cost: get_cost })}}</span>
                </div> -->

                <div v-if="publish_modal.not_enough_credits" class="unlock-cost">
                    <div class="title-container error">
                        <p class="title nomargin" v-html="$t('matches.detail.workProvider.modal.unlockCandidate.notEnoughCredits.title', { credits: publish_modal.missing_credits })"></p>
                    </div>
                </div>
                
                <PurchaseCredits v-if="publish_modal.not_enough_credits" :embedded="true" :notify_save_progress="true" :missingCredits="publish_modal.missing_credits" :creditUnitCost="publish_modal.credit_unit_cost" @cancel="$emit('close')" @purchased_credits="publish_modal.not_enough_credits = false" />

                <p class="title with-error-icon" v-if="!accountIsVerified"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{ $t('workProviderOffer.publish.modal.account_not_verified') }}</p>

                <!-- <p :class="['title title-modalities', { 'nomargin-top': !publish_modal.not_enough_credits }]">{{$t('workProviderOffer.publish.modal.breakdown.title')}}</p>
                <p v-if="work_location === 'remote'" class="modalities" v-html="$t('workProviderOffer.publish.modal.breakdown.desc.remote')"></p>
                <p v-if="work_location === 'regular'" class="modalities" v-html="$t('workProviderOffer.publish.modal.breakdown.desc.regular')"></p> -->
            </div>

            <div v-if="!publish_modal.not_enough_credits" class="cta-container">
                <div class="cta cta-secondary cta-slim" :class="{ 'loading dark': publish_modal.loading, disabled: !accountIsVerified }" @click="$emit('create')">{{$t('workProviderOffer.publish.modal.publish')}}</div>
                <div class="cta cta-outline dark cta-slim" @click="$emit('close')">{{$t('language.words.neverMind')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PurchaseCredits from '../../../../components/Cards/Credits/PurchaseCreditsCard.vue'

export default {
    name: 'PublishWorkProviderOfferModal',

    components: { PurchaseCredits },

    props: {
        publish_modal: {
            type: Object,
            default() {
                return {}
            }
        },

        work_location: {
            type: String,
            default() {
                return ''
            }
        },

        locations: {
            type: Array,
            default() {
                return []
            }
        },
    },

    watch: {
        publish_modal: function(val) {
            this.publish_modal = val;
        },

        'publish_modal.modal': function() {
            const self = this;
            if(self.publish_modal.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    computed: {
        ...mapGetters([
            'accountIsVerified',
        ]),

        get_cost: function() {
            const self = this;
            const default_cost = 500;

            if(self.work_location === 'remote') { return default_cost }
            if(self.locations.length !== 0) { return 400 + (self.locations.length * 100) }

            return default_cost;
        }
    }
}
</script>

